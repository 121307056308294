import { get, post, put, Delete } from "@/api/axios";

// /web/house/label/list get
export const list = params => get('/web/car/manage/list', params);

// /web/house/label/add post
export const add = params => post('/web/car/manage/add', params);

// /web/house/label/update put
export const update = params => put('/web/car/bind/update', params);

// /web/house/label/delete/{id} delete
export const deleteNew = params => Delete('/web/car/manage/delete/' + params.id, '');

//挪车
export const moveCar = params => get('/web/car/manage/move/' + params.id, '');

//重启
export const restartCar = params => get('/web/car/manage/restart/' + params.id, '');

// /web/house/label/view/{id} get
export const view = params => get('/web/car/manage/view/' + params.id, '');

//选择车型列表
export const getcarTypeList = params => get('/web/car/manage/type/list', params)

// 启动开关
export const updateStart = params => put('/web/car/manage/start/update', params);

// 整车休眠
export const updateDormancy = params => put('/web/car/manage/dormancy/update', params);

// 启动状态
export const updateState = params => put('/web/car/manage/state/update', params);

// 查看车辆详情-基础信息
export const detailBase = params => get('/web/car/manage/detail/base/' + params.id, '');

// 查看车辆详情-运营情况
export const orderList = params => get('/web/car/manage/detail/order/list', params);

// 查看车辆详情-运营情况-统计
export const orderCount = params => get('/web/car/manage/detail/order/count', params);

// 查看车辆详情-时长统计
export const durationList = params => get('/web/car/manage/detail/duration/list', params);

// 查看车辆详情-时长统计-统计
export const durationCount = params => get('/web/car/manage/detail/duration/count', params);

// 查看车辆详情-时长统计-车辆行驶轨迹
export const travelDuration = params => get('/web/car/manage/detail/duration/travel', params);

// 车辆列表-车辆详情-检测开始
export const detectionStart = params => get('/web/car/manage/detail/detection/start/' + params, '');

// 车辆列表-车辆详情-检测开始
export const detectionEnd = params => get('/web/car/manage/detail/detection/end/' + params, '');


// 车辆列表-车辆详情-运行情况
export const runDetail = params => get('/web/car/manage/detail/run', params);

// 车辆列表-车辆详情-异常情况
export const exception = params => get('/web/car/manage/detail/exception', params);

//车辆列表-区域列表
export const regionList = params => get('/web/car/manage/region/list', params);

//车辆列表-车型列表
export const typeList = params => get('/web/car/manage/car/type/list', params);

//重启
export const getStateById = params => get('/web/car/manage/car/stateById/' + params, '');