<template>
  <div style="height:850px">
    <div id="container" v-if="isShow" >
      <div
        class="prop1"
        v-if="propsState"
      >
        <div class="title">车辆信息</div>
        <div class="time">车辆编号：{{ form.code }}</div>
        <div class="textMeun">
          <div class="text">车型名称：{{ form.carTypeName }}</div>
          <div class="text">所属区域: {{ form.regionName }}</div>
          <div class="text">当前状态：{{ form.moveState==1?'可用':form.moveState==2?'被预约':form.moveState==3?'使用中':form.moveState==4?'用户上报故障':form.moveState==5?'系统自测故障':form.moveState==6?'被删除':"" }}</div>
        </div>
      </div>
      <div
        class="prop2"
        v-if="propsState"
      >
        <div class="title">轨迹信息</div>
        <div class="time">时间段：
          <el-date-picker
            v-model="createTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
            @change="onCreateTimeHandle"
          >
          </el-date-picker>
        </div>
        <div class="title">起始位置</div>
        <div class="textMeun">
          <div class="text">经度：{{ createStartData.latitude }}</div>
          <div class="text">纬度：{{ createStartData.longitude }}</div>
          <div class="text">位置：{{ createStartData.address }}</div>
        </div>
        <div class="title">结束位置</div>
        <div class="textMeun">
          <div class="text">经度：{{ createEndtData.latitude }}</div>
          <div class="text">纬度：{{ createEndtData.longitude }}</div>
          <div class="text">位置：{{ createEndtData.address }}</div>
        </div>
      </div>
      <div
        class="prop"
        v-if="propsState"
      >
        <div class="title">选中位置</div>
        <div class="time">时间：{{ geometry.time }}</div>
        <div class="textMeun">
          <div class="text">经度：{{ geometry.lat }}</div>
          <div class="text">纬度：{{ geometry.lng }}</div>
          <div class="text">位置：{{ geometry.address }}</div>
        </div>
      </div>

      <div
        class="prop3"
        v-if="propsState"
      >
        <div class="prop3-boxs marginBottom20">
          <div class="title">运行情况</div>
          <div class="prop3_div">
            <div class="prop3_tr ">
              <div class="prop3_th">订单编号</div>
              <div class="prop3_th">使用时间（分钟）</div>
              <div class="prop3_th">轨迹查看</div>
            </div>
            <div v-if="runList.length">
              <div class="prop3_tr prop3Border" v-for="(item,index) in runList" :key="index">
                <div class="prop3_td">{{ item.code }}</div>
                <div class="prop3_td">{{ item.useTime }}</div>
                <div class="prop3_td prop3_td_view" @click="getCheck(item)">查看</div>
              </div>
            </div>
          </div>
        </div>

        <div class="prop3-boxs">
          <div class="title">异常情况</div>
          <div class="prop3_div">
            <div class="prop3_tr ">
              <div class="prop3_th">发现异常情况</div>
              <div class="prop3_th">异常类型</div>
              <div class="prop3_th">故障前1小时轨迹</div>
            </div>
            <div v-if="runList.length">
              <div class="prop3_tr prop3Border" v-for="(item,index) in abnormalList" :key="index">
                <div class="prop3_td">{{ item.createTime }}</div>
                <div class="prop3_td">{{ item.content }}</div>
                <div class="prop3_td prop3_td_view" @click="getCheckAbnormal(item)">查看</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { manageTravel, travelAdress } from "@/api/order/index";
import { travelDuration,runDetail,exception } from "@/api/car/manage";
// import { carDurationTravel } from "@/api/sale/manage";
import dayjs from "dayjs";
let TMap, map;
export default {
  name: "TMap",
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      runList:[],
      abnormalList:[],
      createStartData: {
        latitude: "",
        longitude: "",
        address: "",
      },
      createEndtData: {
        latitude: "",
        longitude: "",
        address: "",
      },
      createTime: [],
      map: null,
      path: [],
      polylineLayer: null,
      marker: null,
      propsState: false,
      geometry: {
        time: "",
        lat: "",
        lng: "",
        address: "",
      },
      isShow:true,
    };
  },
  mounted() {
    // this.$route.query.id && this.getView() ;
    this.$route.query.id && this.getRun();
    this.$route.query.id && this.getAbnormal();
    this.createTime = [dayjs(this.form.endTime).subtract(1, 'hour'),dayjs(this.form.startTime)];
    this.onCreateTimeHandle([dayjs(this.form.endTime).subtract(1, 'hour'),dayjs(this.form.startTime)]);
  },
  methods: {
    onCreateTimeHandle(e) {
      const createTimeStart = dayjs(e[0]).format("YYYY-MM-DD HH:mm:ss");
      const createTimeEnd = dayjs(e[1]).format("YYYY-MM-DD HH:mm:ss");
      console.log(createTimeStart, createTimeEnd);
      travelDuration({
        id: this.$route.query.id,
        createTimeStart,
        createTimeEnd,
      }).then((res) => {
        const { data } = res;
        let newData = res.data.length ? res.data : [{
            latitude:31.348855,
            longitude:121.498279,
            time:dayjs().format('YYYY-MM-DD HH:mm:ss')
          },{
            latitude:31.348855,
            longitude:121.498279,
            time:dayjs().format('YYYY-MM-DD HH:mm:ss')
          }];
          this.path = newData
          this.initMap();
          newData.forEach((item, index) => {
          if (index === 0 || index === newData.length - 1) {
            travelAdress({
              id: this.$route.query.id,
              latitude: newData[index].latitude,
              longitude: newData[index].longitude,
            }).then((req) => {
              if (index === 0) {
                this.createStartData = {
                  latitude: newData[index].latitude,
                  longitude: newData[index].longitude,
                  address: req.data.address,
                };
              }
              if (index === newData.length - 1) {
                this.createEndtData = {
                  latitude: newData[index].latitude,
                  longitude: newData[index].longitude,
                  address: req.data.address,
                };
              }
            });
          }
        });
      });
    },
    initMap() {
      this.isShow = false

      TMap = window.TMap;
      //设置地图中心点
      let center = new TMap.LatLng(
        this.path[0].latitude,
        this.path[0].longitude
      );
      let paths = [];
      this.path.map((item) => {
        paths.push(new TMap.LatLng(item.latitude, item.longitude));
      });
      // 初始化地图
      this.isShow = true
      setTimeout(() => {
        this.isShow = true

      },500)
       // 防止重复绘制地图
       if(!this.map) {
        this.map = new TMap.Map("container", {
        zoom: 17,
        center: center,
        // baseMap: {  // 设置卫星地图
        //   type: 'satellite'
        // }
      });
      } else {
        this.map.setCenter(center);
      }
      console.log(this.map,'this.map');

      this.polylineLayer = new TMap.MultiPolyline({
        map: this.map, // 绘制到目标地图
        // 折线样式定义
        styles: {
          style_blue: new TMap.PolylineStyle({
            color: "rgb(64, 158, 255)", // 线填充色
            width: 4, // 折线宽度
            borderWidth: 2, // 边线宽度
            borderColor: "#FFF", // 边线颜色
            lineCap: "round", // 线端头方式
            eraseColor: "rgb(172,172,172)", //擦除路径的颜色
            // arrowOptions:true,
            showArrow:true,
            arrowOptions:{
              width:10,
              height:10,
              space:10
            }
          }),
        },
        geometries: [
          {
            id: "erasePath",
            styleId: "style_blue",
            paths: paths,
          },
        ],
      });

      let geometriesList = [];
      this.path.map((item, index) => {
        if(index !== 0 && index !== paths.length - 1) {
          
        }
        if (index === 0) {
        } else if (index === paths.length - 1) {

        } else {
          geometriesList.push({
            id: "meun" + index,
            styleId: "meun",
            position: new TMap.LatLng(item.latitude, item.longitude),
          });
        }
      });
        geometriesList.push({
            id: 'start',
            styleId: "start",
            zIndex:999999999999,
            position: new TMap.LatLng(this.path[0].latitude, this.path[0].longitude),
          });
          geometriesList.push({
            id: 'end',
            styleId: "end",
            zIndex:999999,
            position: new TMap.LatLng(this.path[paths.length - 1].latitude, this.path[paths.length - 1].longitude),
          });
      this.marker = new TMap.MultiMarker({
        map: this.map, // 绘制到目标地图
        styles: {
          meun: new TMap.MarkerStyle({
            width: 25,
            height: 37,
            anchor: { x: 16, y: 32 },
            // src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png",
            zIndex:1,
          }),
          start: new TMap.MarkerStyle({
            width: 25,
            height: 37,
            anchor: { x: 16, y: 32 },
            zIndex:999999999,
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png",
          }),
          end: new TMap.MarkerStyle({
            width: 25,
            height: 37,
            zIndex:999999,
            anchor: { x: 16, y: 32 },
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png",
          }),
        },
        geometries: geometriesList,
      });
      
      this.marker.on("click", this.eventClick);
    },
    eventClick(e) {
      console.log(e.geometry);
      travelAdress({
        id: this.$route.query.id,
        latitude: e.geometry.position.lat,
        longitude: e.geometry.position.lng,
      }).then((res) => {
        if (res.code === 200) {
          this.propsState = true;
          console.log(res, 11111);
          this.geometry = {
            time: this.path[Number(e.geometry.id.replace("meun", ""))].time,
            address: res.data.address,
            lat: e.geometry.position.lat,
            lng: e.geometry.position.lng,
          };
          console.log("geometries", this.geometry);
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    // getView() {
      // this.loading = true;
      // travelDuration({
      //   id: this.$route.query.id,
      //   createTimeStart:dayjs(this.form.endTime).subtract(1, 'hour'),
      //   createTimeEnd:dayjs(this.form.startTime),
      // }).then((res) => {
      //   if (res.code === 200) {
      //     this.loading = false;
      //     // res.data.map((item) => {
      //     //   paths.push(new TMap.LatLng(item.latitude, item.longitude));
      //     // });
      //     console.log(res.data,'1111111111');
      //     this.path = res.data.length ? res.data : [{
      //       latitude:31.348855,
      //       longitude:121.498279,
      //       time:dayjs().format('YYYY-MM-DD HH:mm:ss')
      //     },{
      //       latitude:31.348855,
      //       longitude:121.498279,
      //       time:dayjs().format('YYYY-MM-DD HH:mm:ss')
      //     }];
      //     this.initMap();
      //   } else {1
      //     this.$router.go(-1);
      //     this.$message.error(res.msg);
      //   }
      // });
    // },
    getRun() {
      this.loading = true;
      runDetail({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.runList = res.data
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
    getAbnormal() {
      this.loading = true;
      exception({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.abnormalList = res.data
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
    
    getCheck(e) {
      this.createTime = [e.startTime,e.returnTime]
      this.onCreateTimeHandle([e.startTime,e.returnTime]);
    },
    getCheckAbnormal(e) {
      this.createTime = [e.startTime,e.createTime]
      this.onCreateTimeHandle([e.startTime,e.createTime]);
    }

   
  },
};
</script>

<style>
#container {
  height: 600px;
  position: relative;
}
.prop1 {
  position: absolute;
  left: 20px;
  top: 0;
  background-color: #fff;
  padding: 20px;
  z-index: 2000;
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.prop2 {
  position: absolute;
  left: 20px;
  bottom: 10px;
  background-color: #fff;
  padding: 20px;
  z-index: 2000;
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.prop {
  position: absolute;
  left: 20px;
  bottom: -240px;
  background-color: #fff;
  padding: 20px;
  z-index: 2000;
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.prop3 {
  position: absolute;
  right: 20px;
  top: 0;
  z-index: 2000;
  width: 330px;
}
.prop3-boxs {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}
.prop3_div {
  width: 100%;
  /* display: flex; */
  max-height: 300px;
  min-height: 150px;
  overflow-y:auto
}
.prop3_tr {
  display: flex;
  text-align: center;
  /* align-items: center; */
}
.prop3Border {
  border-bottom: 1px solid #ddd;
}
.prop3Margin {
  margin-top: 10px;
}
.prop3_th {
  width: 33%;
  /* font-size: 12px; */
}
.prop3_td {
   width: 33%;
   word-break: break-all;
   font-size: 12px;
   padding: 6px 2px;
}
.prop3_td_view {
  cursor: pointer;
  color: #02a7f0;
}



.title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}
.time {
  font-size: 16px;
  margin: 5px 0 20px 0;
}
.textMeun {
  font-size: 14px;
  color: #333;
  margin-bottom: 15px;
  line-height: 20px;
}
.text {
  margin-bottom: 10px;
}

/deep/ .el-picker-panel {
  z-index: 99999 !important;
}
</style>
